export const ONBOARDING_API = 'onboarding-management/v1/users';
const V1 = 'v1';
const V2 = 'v2';
export const ENDPOINTS = {
  SIGNUP_OTPS: `auth-management/${V1}/signup-otps`,
  V1_USERS: `auth-management/${V2}/users`,
  VALIDATE: `${V1}/passwordToken/validate`,
  V2_PASSWORD: `${V2}/password`,
  V1_CREDENTIALS: `${V1}/credentials`,
  V2_CREDENTIALS: `auth-management/${V2}/credentials`,
  V1_TOKEN: `${V1}/token`,
  V1_PROSPECTS: `onboarding-management/${V1}/prospects`,
  COMPANY_INFO: 'company-info',
  INDUSTRIES: `reference-data-management/${V1}/onboarding-forms/industries`,
  COUNTRIES: `public/${V1}/countries?filterRestrictedCountriesForOnboarding=false`,
  NATIONALITIES: `public/${V1}/nationalities?filterRestrictedNationalitiesForOnboarding=false`,
  BUSINESS_INFO: 'business-info',
  USERS: `auth-management/${V2}/users`,
  STAKEHOLDER_INDIVIDUALS: 'stakeholder-individuals',
  STAKEHOLDER_COMPANIES: 'stakeholder-companies',
  THIRD_PARTIES: 'third-parties',
  INDIVIDUAL_STAKEHOLDER_DOCUMENTS: 'stakeholder-individual-documents',
  COMPANY_STAKEHOLDER_DOCUMENTS: 'stakeholder-company-documents',
  APPLICATION_SUBMISSION: '/submit',
  OTHER_JURISDICTION_COUNTRIES: `public/${V1}/other-countries-of-incorporation`,
  PAYMENT_COUNTRIES: `bank-account-management/${V1}/countries?payments-filter=true`,
  COUNTRY_DIAL_CODES: `public/${V1}/countries-dial-codes`,
  PHONE_VALIDATION_OTPS: `auth-management/${V1}/phone-validation-otps`,
  UPDATE_PHONE: `auth-management/${V1}/update-phone`,
  GENERATE_MFA_OTP: `auth-management/v1/mfa-process`,
  VERIFY_MFA_OTP: `auth-management/v3/login`,
  SSO_LOGIN: `user-identity-management/${V1}/credentials`,
  SSO_USER_INFO: `user-identity-management/v1/users/`,
  BUSINESS_ACCOUNT_APPLICATIONS_LIST: `client-management/v1/users/`,
  USER_APPLICATION_ACCESS: `user-identity-management/v1/users/{userCode}/application-access`,
  SSO_USER_FULL_INFO: `user-identity-management/v1/users/{userCode}`,
  INITIATE_CHANGE_PASSWORD_OTP: `user-identity-management/v1/update-password-otp`,
  UPDATE_USER_PROFILE_PASSWORD: `user-identity-management/v1/update-password`,
  LOGOUT_USER: `user-identity-management/v1/logout`,
  SSO_RESET_PASSWORD_EMAIL_VERIFY: `user-identity-management/v1/reset-password-otp`,
  VERIFY_SSO_RESET_PASSWORD_OTP: `user-identity-management/v1/verify-reset-password-otp`,
  RESET_SSO_USER_PASSWORD: `user-identity-management/v1/reset-password`,
  SSO_FLAG_URL: `reference-data-management/public/v1/sso-flag`,
  REFRESH_TOKEN: `user-identity-management/v1/refresh-token`,
  COMPANY_MANAGEMENT_APPLICATIONS_LIST: `v2/incorporation/companies`,
  INVOICE_MANAGEMENT_APPLICATIONS_LIST: `v2/invoice-companies`
};
export const HUBSPOT_INTEGRATION = {
  HUBSPOT_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/8462932/b88e67a2-d3e6-4c16-a6a0-d182653e8c5c'
};
export const PARTNER_HUBSPOT_INTEGRATION = {
  HUBSPOT_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/8462932/8d9e1848-3fbf-4b98-b98d-85d7e39f099b'
};
export const RESOURCES_HUBSPOT_INTEGRATION = {
  HUBSPOT_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/8462932/00874903-1a34-436f-b9ce-ad5def68956e'
};
export const REFER_FRIEND_HUBSPOT_INTEGRATION = {
  HUBSPOT_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/8462932/2b1e3b83-f48f-4d25-bf0f-b2cffbaf5130'
};
export const GEO_LOCATION_API = 'https://geolocation-db.com/json/';

export const COMPANY_REGISTRATION_WAITING_FLOW = {
  HUBSPOT_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/8462932/6f6ad58b-b78e-4024-8a8b-672d1f717e17'
};

export const COMPANY_INCORPORATION = {
  HUBSPOT_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/8462932/4e3354c6-126f-4948-9393-a206a0b2d954'
};

export const CHECK_REFERRAL_CODE_HUBSPOT_INTEGRATION = {
  HUBSPOT_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/8462932/91f394aa-5c05-4f30-b80a-f0745bedf2d6'
};
export const BUSINESS_ACCOUNT_REVIEWS_QUIZ = {
  HUBSPOT_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/8462932/47a5c076-39b2-4ca1-8dff-6d3324b3054f'
};
export const PAYPAL_CALCULATOR = {
  HUBSPOT_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/8462932/fde53732-306f-47ab-a6c1-8fc50f70878a'
};
export const NEW_COMPANY_INCORPORATION = {
  HUBSPOT_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/8462932/616c1ccd-5189-4381-82c6-fdc2f6fc6bf6'
};
export const CHECK_COST_INCORPORATION = {
  HUBSPOT_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/8462932/6bb9cda8-8f3e-4136-86e9-cd487f869542'
};
